import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";
import { AppDispatch } from "../../store/store";
import BASE_URL from "../../utils/apiurl";
import { toast, ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface Requests {
  _id: string;
  name: string;
  email: string;
  password: string;
}

const RequestsPage = () => {
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const [studentRequests, setStudentRequests] = useState<Requests[] | null>(null);
  const [teacherRequests, setTeacherRequests] = useState<Requests[] | null>(null);
  const [activeTab, setActiveTab] = useState<"student" | "teacher">("student");

  const type = user.type;

  const getRequests = async () => {
    try {
      const res = await axios.get(BASE_URL + "get-requests/", {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });
      setStudentRequests(res.data.students);
      setTeacherRequests(res.data.teachers);
    } catch (error) {
      console.error("Error fetching requests:", error);
    }
  };

  const onSignUp = async (
    name: string,
    email: string,
    password: string,
    type: string,
    standard: string,
    letter: string,
    school_id: string
  ) => {
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("type", type);
      formData.append("standard", standard);
      formData.append("letter", letter);
      formData.append("school_id", school_id);

      const response = await axios.post(BASE_URL + "signup/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Signup error:", error);
    }
  };

  const deleteRequest = async (request_id: string) => {
    try {
      const res = await axios.delete(`${BASE_URL}delete-requests/${request_id}`, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });
    } catch (error) {
      console.error("Deletion error:", error);
    }
  };

  useEffect(() => {
    getRequests();
  }, []);

  const handleAccept = async (request: Requests, type: string) => {
    await onSignUp(
      request.name,
      request.email,
      request.password,
      type,
      "",
      "",
      user.school_id
    );

    toast.success(`Successfully accepted ${request.name}!`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });

    if (activeTab === "student") {
      setStudentRequests((prev) => prev?.filter((r) => r._id !== request._id) || null);
    } else {
      setTeacherRequests((prev) => prev?.filter((r) => r._id !== request._id) || null);
    }

    await deleteRequest(request._id);
  };

  const handleReject = async (request: Requests) => {
    toast.error(`Rejected ${request.name}'s request.`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });

    if (activeTab === "student") {
      setStudentRequests((prev) => prev?.filter((r) => r._id !== request._id) || null);
    } else {
      setTeacherRequests((prev) => prev?.filter((r) => r._id !== request._id) || null);
    }

    await deleteRequest(request._id);
  };

  return (
    <div className="max-w-7xl mx-auto p-4">
      <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          transition={Bounce}
          toastClassName="bg-white text-sm text-gray-900 border border-gray-200 rounded-md shadow-md p-4 max-w-xs flex items-center" // Tailwind classes for the toast
          bodyClassName="whitespace-nowrap overflow-hidden text-ellipsis" 
      />
      {type === "admin" ? (
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          <div className="flex justify-start mb-5">
            {[
              { label: "Student", key: "student" },
              { label: "Teacher", key: "teacher" },
            ].map((tab) => (
              <div
                key={tab.key}
                className={`tab-item text-gray-900 text-base py-2 px-5 mx-1 cursor-pointer border-b-2 ${
                  activeTab === tab.key ? "border-black" : "border-transparent"
                } hover:border-gray-300`}
                onClick={() => setActiveTab(tab.key as "student" | "teacher")}
              >
                {tab.label}
              </div>
            ))}
          </div>

          <div className="request-list">
            {(studentRequests?.length|| teacherRequests?.length) ? (
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Name
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Email
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(activeTab === "student" ? studentRequests : teacherRequests)?.map((request) => (
                    <tr key={request._id} className="bg-white border-b">
                      <td className="px-6 py-4 font-medium text-gray-900">{request.name}</td>
                      <td className="px-6 py-4">{request.email}</td>
                      <td className="px-6 py-4 flex gap-4">
                        <button
                          onClick={() => handleAccept(request, activeTab)}
                          className="inline-flex w-full justify-center rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-400 sm:ml-3 sm:w-auto"
                        >
                          Accept
                        </button>
                        <button
                          onClick={() => handleReject(request)}
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white hover:bg-red-500 sm:mt-0 sm:w-auto"
                        >
                          Reject
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="flex flex-col items-center justify-center h-screen w-full">
              <p className="font-bold text-gray-700 -mt-48 truncate text-lg leading-5 mb-6">
                There are no requests at the moment.
              </p>
            </div>
            )}
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-screen w-full">
          <p className="font-bold text-gray-700 -mt-48 truncate text-lg leading-5 mb-6">
            You cannot access requests.
          </p>
        </div>
      )}
    </div>
  );
};

export default RequestsPage;
