import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import BASE_URL from '../../utils/apiurl';

const VerifyEmail = () => {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const [userId, setUserId] = useState<string>(
        searchParams.get("userId") ?? ""
      );

    const verify = async () => {
        try {
            const formData = new FormData();
            formData.append("user_id", userId)

            const res = await axios.patch(BASE_URL+"verify-email/", formData, {
                headers: {
                    'Content-Type': 'mulitpart/form-data'
                }
            })

            console.log(res.data)
            navigate("/login")
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (userId) {
            verify()
        }
    }, [])

    return (
        <div className="">
        {!userId && (
            <h1>Invalid route, email not registered</h1>
        )}
        </div>
    )
}

export default VerifyEmail