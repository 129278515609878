/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./landing-page.css";
import { Link } from "react-router-dom";

export default function LandingPage() {

  return (
    <div>
      {/* Navbar */}
      <div>
        <div className="flex lg:px-20 justify-between relative  items-center p-5">
          <div className="flex gap-3">
            <img src="/images/lotus-logo-dark.svg" alt="lotus-logo" />
          <div className="flex">
            <h1 className="font-extrabold text-[28px] text-center uppercase">Vidya.Ai</h1>
          </div>
            </div>
          <Link to={'/login'}><button className="bg-slate-200  rounded-md lg:p-3 p-2" >Get Started</button></Link>
        </div>
      </div>
      {/* Hero Section */}
      <div className="bg-[url('../public/images/home-banner.svg')] h-max bg-bottom ">
        <div className="lg:py-[220px] py-[172px] lg:px-0 px-10  items-center flex flex-col gap-4 justify-center text-center text-white">
          <p className="uppercase tracking-[4px]">
            Together, we’re reimagining education for all.
          </p>
          <h1 className="font-unbouded uppercase lg:text-[42px] lg:leading-[46px] leading-[32px] text-[32px] font-[900] max-w-[768px] ">
            Empowering education with{" "}
            <span className="text-[#E7B127]">AI and compassion</span>
          </h1>
          <p className="max-w-[768px] text-lg">
            VIDYA.ai, in partnership with The Akanksha Foundation, leverages
            cutting-edge AI technology to provide personalized learning tools to
            students in municipal schools, enhancing learning outcomes and
            shaping brighter futures.
          </p>
        </div>
      </div>
      {/* Your Smart Study Campion */}
      <div className="flex lg:flex-row flex-col xl:gap-0 lg:gap-8 py-20 max-w-[1441px] mx-auto xl:px-0 lg:px-12 justify-center md:px-10 px-5 items-center">
        {/* Left Section */}
        <div className="lg:w-[50%] w-[100%] relative">
          <div className="flex">
            <h2 className="uppercase text-start text-[#235390] font-black lg:text-[42px] lg:leading-[42px] text-[32px] leading-[32px]">
              Vidya.Ai
            </h2>
          </div>
          <h2 className="text-start uppercase mt-3 font-black lg:text-[42px] lg:leading-[42px] text-[32px] leading-[32px]">
            Your Smart Study Compaion
          </h2>
          <p className="mt-6 text-md text-start lg:block hidden">
            Fast and efficient way to recall classroom lessons, prepare for
            exams, complete homework, and deepen your understanding of any
            topic.{" "}
          </p>
          <p className="mt-6 text-md text-start lg:block hidden">
            Designed specifically for the Bala Bharati curriculum, VIDYA.AI
            harnesses the power of advanced AI-based Large Language Models
            (LLMs), trained using cutting-edge prompt engineering and Automatic
            Speech Recognition (ASR) technology. This innovative tool creates
            easy-to-use class notes and reference materials, providing municipal
            school students with seamless access to their learning resources.
          </p>
        </div>
        {/* Right Section */}
        <div className="lg:w-[50%] w-[100%] flex flex-col ">
          <img alt="smart-study"  src="./images/section-2-img.svg" />
          <p className="mt-6 text-md text-start block lg:hidden">
            Fast and efficient way to recall classroom lessons, prepare for
            exams, complete homework, and deepen your understanding of any
            topic.
          </p>
          <p className="mt-6 text-md text-start block lg:hidden">
            Designed specifically for the Bala Bharati curriculum, VIDYA.AI
            harnesses the power of advanced AI-based Large Language Models
            (LLMs), trained using cutting-edge prompt engineering and Automatic
            Speech Recognition (ASR) technology. This innovative tool creates
            easy-to-use class notes and reference materials, providing municipal
            school students with seamless access to their learning resources.
          </p>
        </div>
      </div>
      {/* Innovator Section */}
      <div className="bg-gradiant">
        <div className="pt-20 pb-10 xl:pl-40 lg:pl-20 lg:px-20 md:px-10 px-5 flex lg:flex-row flex-col max-w-[1441px] mx-auto justify-center items-center">
          {/* Left Section */}
          <div className="lg:w-[50%] w-[80%]  flex lg:block justify-center lg:justify-start flex-col">
          <div className="lg:hidden block ">
           <h2 className="text-start  lg:text-[42px] lg:leading-[42px] text-[32px] font-black leading-[32px]">
              Meet The <span className="text-[#E7B127]">Innovator</span>
            </h2>
            <div className="flex relative">
              <h6 className="text-4xl mt-2 text-[#235390] font-[500px]">
                Ishan Palicha
              </h6>
           </div>
            </div>
            <img  alt="innovator" className="lg:mt-0 mt-4" src="./images/innovator-img.svg" />
          </div>
          {/* Right Section */}
          <div className="lg:w-[50%] w-[100%]">
           <div className="lg:block hidden">
           <h2 className="text-start lg:block hidden lg:text-[42px] lg:leading-[42px] text-[32px] font-black leading-[32px]">
              Meet The <span className="text-[#E7B127]">Innovator</span>
            </h2>
            <div className="flex relative">
              <h6 className="text-4xl mt-2 text-[#235390] font-[500px]">
                Ishan Palicha
              </h6>
           </div>
            </div>
            <p className="mt-5 text-md text-start">
              VIDYA.ai is the brainchild of Ishaan Palicha, a forward-thinking
              Year 11 student passionate about using technology to create
              meaningful social impact improving education for students who face
              barriers to learning.{" "}
            </p>
            <p className="text-md mt-5 text-start">
              Collaborating with The Akanksha Foundation, which has been
              transforming the lives of children from low-income communities
              through education for over three decades, Ishaan designed VIDYA.ai
              as a practical tool to enhance classroom experiences. By
              integrating AI with the Bala Bharati curriculum, VIDYA.ai supports
              Akanksha’s mission to provide equitable access to quality
              education, empowering students to overcome challenges and achieve
              their full potential.
            </p>
          </div>
        </div>
      </div>
      {/* Our Vision */}
      <div className="max-w-[1441px] pt-20 mx-auto justify-center items-center  lg:px-20 md:px-10 px-5">
        <div className="flex flex-col justify-center items-center">
          <h1 className="font-unbouded lg:text-[42px] lg:leading-[46px] leading-[32px] text-[32px] font-black text-[#235390]">
            Our Vision
          </h1>
          <h6 className="lg:text-[42px] text-center lg:leading-[42px] text-[32px] leading-[32px] font-black mt-5">
            Let Learning Reach Everyone Throgh Power Of Ai
          </h6>
          <p className="text-md mt-6 text-start">
            By making learning resources available on demand, VIDYA.AI helps
            every student thrive, irrespective of their learning environment. In
            many classrooms, students grasp concepts at varying paces, and large
            class sizes can make personalized attention challenging. VIDYA.AI
            addresses this by leveraging AI to:
          </p>
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2  grid-cols-1 gap-4 pt-10 ">
          <div className="shadow-md rounded-2xl p-4">
            <img
              alt="our-vision-first"
              src="./images/our-vision-first-image.svg"
              height={319}
              width={192}
              style={{ width: "100%" }}
            />
            <p className="py-4">
              Summarize lessons taught in the classroom using teacher voice
              recordings and textbook references.
            </p>
          </div>
          <div className="shadow-md  rounded-2xl p-4">
            <img
              alt="our-vision-second"
              src="./images/our-vision-second-image.svg"
              height={319}
              width={192}
              style={{ width: "100%" }}
            />
            <p className="py-4">
              Provide instant access to key content through a user-friendly web
              or app interface.
            </p>
          </div>
          <div className="flex flex-col justify-center items-center">
          <div className="shadow-md rounded-2xl p-4 ">
            <img
              alt="our-vision-third"
              src="./images/our-vision-third-image.svg"
              height={319}
              width={192}
              style={{ width: "100%" }}
              className="lg:w-[100%] md:w-[346px] w-[100%]"
            />
            <p className="py-4 text-start">
              Ensure deeper engagement and retention of concepts with tools
              tailored to the Bala Bharathi curriculum.
            </p>
          </div>
          </div>
        </div>
      </div>
      {/* What Make Us Unique */}
      <div className="max-w-[1441px] py-20 xl:px-0 lg:px-12 md:px-10 px-5 mx-auto justify-center items-center mt-10">
        <h1 className="font-unbouded lg:text-[42px] lg:leading-[46px] leading-[32px] text-[32px] font-[900]">
          What Makes <span className="text-[#235390]">VIDYA.AI</span> Unique? 
        </h1>
        <p className="mt-5 max-w-[653px] text-start">
          VIDYA.AI’s innovative approach combines advanced AI with the specific
          needs of municipal school students:
        </p>
        <div className="flex lg:flex-row flex-col  xl:gap-0 lg:gap-4">
          <div className="lg:w-[50%] w-[100%] flex  justify-center">
            <img
              src="./images/vidya.ai-diagram-working.svg"
              alt="what-makes-us-Speacial"
            />
          </div>
          <div className="lg:w-[50%] w-[100%] flex flex-col gap-4">
            <div className="text-start">
                <span className="text-start">
                  Trained on Bala Bharathi Curriculum:
                </span>
              Ishaan Palicha has utilized advanced prompt engineering to train
              the AI model with the entire curriculum used in municipal schools.
              This ensures that the content is accurate, relevant, and aligned
              with what students learn in class.
            </div>
            <div className="text-start">
              <div className="flex gap-2 mb-2">
                <img
                  alt="check-icon"
                  src="./images/check-icon.svg"
                  height={20}
                  width={20}
                />
                <span>Classroom Voice-to-Notes Conversion</span>
              </div>
              The AI listens to discussions, extracts key points, and creates
              concise, structured summaries.
            </div>
            <div className="text-start">
              <div className="flex gap-2 mb-2">
                <img
                  alt="check-icon"
                  src="./images/check-icon.svg"
                  height={20}
                  width={20}
                />
                <span>Deeper Understanding of Concepts</span>
              </div>
              Summarized notes help students engage critically with the
              material, promoting active learning and better recall.
            </div>
            <div className="text-start">
              <div className="flex gap-2 mb-2">
                <img
                  alt="check-icon"
                  src="./images/check-icon.svg"
                  height={20}
                  width={20}
                />
                <span>Accessible Learning Anytime:</span>
              </div>
              Students can revisit concepts at their own pace through the web or
              app interface
            </div>
            <div className="text-start">
              <div className="flex gap-2 mb-2">
                <img
                  alt="check-icon"
                  src="./images/check-icon.svg"
                  height={20}
                  width={20}
                />
                <span>Teacher-Friendly Features:</span>
              </div>
              Teachers can review sessions, input additional material, and use
              the notes to address student queries efficiently
            </div>
          </div>
        </div>
      </div>
      {/* Our Partner */}
      <div className="bg-[#FAFAFA]">
        <div className="flex  lg:flex-row flex-col gap-20 pt-10 pb-20 xl:px-10 lg:px-12 md:px-10 px-5 max-w-[1441px] mx-auto justify-center items-center">
          {/* Left Section */}
          <div className="lg:w-[50%] w-[100%]">
            <h2 className="capitilize text-start font-unbouded lg:text-[42px] lg:leading-[46px] leading-[32px] text-[32px] font-bold">
              Our Partnership with{" "}
              <p className="uppercase text-[#235390]">Akansha Foundation</p>
            </h2>
            <div className="lg:w-[50%] w-[100%]  justify-center relative flex lg:hidden">
            <img
              alt="our-partner"
              className="lg:absolute lg:top-[-15rem]"
              src="./images/our-partnerships-img.svg"
            />
          </div>
            <p className="text-md mt-5 text-start">
              In collaboration with The Akanksha Foundation, a leader in
              providing high-quality education to children from low-income
              communities—VIDYA.AI is transforming how municipal school students
              access and engage with learning.
            </p>
            <p className="text-md mt-5 text-start">
              For over three decades, Akanksha has been empowering students
              through holistic education that nurtures knowledge, skills, and
              values. This aligns seamlessly with VIDYA.AI’s learning tools,
              ensuring that students receive the personalized support they need
              to excel academically and build brighter futures. Together, we are
              bridging gaps in education and creating pathways for lifelong
              success.
            </p>
          </div>
          {/* Right Section */}
          <div className="lg:w-[50%] w-[100%] relative lg:block hidden">
            <img
              alt="our-partner"
              className="lg:absolute lg:top-[-15rem]"
              src="./images/our-partnerships-img.svg"
            />
          </div>
        </div>
      </div>
      {/* Why Vidya Matters */}
      <div className="max-w-[1441px] pt-20 pb-10 xl:px-0 lg:px-12 md:px-10 px-5 mx-auto">
        <div className="max-w-[70%]">
          <h1 className="text-start lg:text-[42px] text-[32px] lg:leading-[46px] leading-[32px]  font-black font-neu">
            Why <b className="text-[#235390] ">VIDYA.ai</b> Matters? 
          </h1>
        </div>
        <div className="flex lg:flex-row  lg:items-center flex-col justify-center mt-5">
          <div className="lg:w-[50%] w-[100%]">
            {/* Features */}
            <div className="flex flex-col gap-4">
              {" "}
              <div>
                <p className="text-start mt-3 max-w-[738px]">
                  VIDYA.ai is more than a tool—it’s a revolutionary solution to
                  address the challenges faced by students and educators in
                  underserved communities. By tailoring learning tools to the
                  Bala Bharathi curriculum, VIDYA.AI ensures
                </p>
                <div className="flex gap-4 mt-3">
                  <img
                    alt="check-icon"
                    src="./images/check-icon.svg"
                    height={20}
                    width={20}
                  />
                  <span className="text-lg">Teacher-Friendly Features:</span>
                </div>
                Teachers can review sessions, input additional material, and use
                the notes to address student queries efficiently
              </div>
              <div>
                <div className=" flex gap-2 mb-2">
                  <img
                    alt="check-icon"
                    src="./images/check-icon.svg"
                    height={20}
                    width={20}
                  />
                  <span className="text-lg">
                    Seamless Alignment with Classroom Learning
                  </span>
                </div>
                Summarized notes directly reflect what’s taught in school,
                ensuring consistency.
              </div>
              <div>
                <div className="flex gap-2 mb-2">
                  <img
                    alt="check-icon"
                    src="./images/check-icon.svg"
                    height={20}
                    width={20}
                  />
                  <span className="text-lg">Improved Retention and Recall:</span>
                </div>
                AI-generated notes enhance memory and understanding of complex
                topics.
              </div>
            </div>
            <div>
              <div className="flex gap-2 mb-2 mt-2">
                <img
                  alt="check-icon"
                  src="./images/check-icon.svg"
                  height={20}
                  width={20}
                />
                <span className="text-lg">Support for Missed Classes:</span>
              </div>
              Students can catch up on lessons easily.
            </div>
            <div>
              <div className="flex gap-2 mb-2 mt-2">
                <img
                  alt="check-icon"
                  src="./images/check-icon.svg"
                  height={20}
                  width={20}
                />
                <span className="text-lg">Empowered Teachers:</span>
              </div>
              With structured notes, voice transcripts and additional support,
              teachers can focus on delivering impactful lessons.
            </div>
            <p className="text-md mt-3">
              By making learning resources available on demand, VIDYA.AI helps
              every student thrive, irrespective of their learning environment.
            </p>
          </div>
          <div className="lg:w-[50%] w-[100%] lg:mt-0 mt-20 float-right">
            <img alt="vidya.ai" src="/images/vidya.ai-matters.png" />
          </div>
        </div>
      </div>
      {/* Join The Moment */}
      <div className=" bg-[#3247C60D]">
        <div className="max-w-[1441px] mx-auto justify-center items-center p-20 lg:px-20 md:px-10 px-5">
          <h1 className="text-center  font-bold lg:text-[42px] text-[32px]">
            Join The <span className="text-[#235390]">Moment</span>
          </h1>
          <p className="text-center text-md">
            We believe education transformation is a collective effort. Here’s
            how you can contribute
          </p>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-5">
            <div className="p-4 h-[173px] rounded-lg bg-[#3247C60D]">
              <h6 className="text-[#235390] font-semibold">Partner</h6>
              <p className="text-md">
                Collaborate with us to bring VIDYA.ai to more schools.
              </p>
            </div>
            <div className="p-4 h-[173px] rounded-lg bg-[#3247C60D] ">
              <h6 className="text-[#235390] font-semibold">Grants</h6>
              <p className="text-md">
                Help us web hosting grants and AI credits that are used for the
                technology so we can reach more students and expand the
                platform’s capabilities
              </p>
            </div>
           <div className="lg:w-[100%] md:w-[768px] w-[100%] flex md:justify-center justify-start">
           <div className="p-4 h-[173px] rounded-lg bg-[#3247C60D] justify-center md:w-[467px] w-[100%]">
              <h6 className="text-[#235390] font-semibold">Volunteer</h6>
              <p className="text-md">
                Share your expertise to refine and implement VIDYA.ai
                technology.
              </p>
            </div>
          </div>
           </div>
        </div>
      </div>
      {/* Building A future */}
      <div className="flex items-center lg:flex-row flex-col lg:gap-14 gap-5 lg:py-20 py-10  xl:px-0 lg:px-12 md:px-10 px-5 max-w-[1441px] mx-auto">
        <div className="lg:w-[50%] w-[100%]">
        <h1 className="lg:text-start text-center mb-5 lg:hidden block lg:text-[42px] text-[32px] lg:leading-[46px] leading-[36px] font-black font-neu">
            <span className="text-[#235390]">Building a future:</span>
            <br /> From vision to impact
          </h1>
         <div className="flex justify-center">
         <img
            src="/images/building-a-future-img.svg"
            alt="buliding a Future"
          />
         </div>
        </div>
        <div className="lg:w-[50%] w-[100%]">
          <h1 className="text-start lg:block hidden lg:text-[42px] text-[32px] lg:leading-[46px] leading-[36px] font-black font-neu">
            <span className="text-[#235390]">Building a future:</span>
            <br /> From vision to impact
          </h1>
          <p className="lg:pt-5 text-start">
            VIDYA.AI has reached a viable platform phase testing accuracy of the
            trained LLM model and ASR technology. Ishaan Palicha is dedicating
            his time and efforts—mentored by an expert—to create a
            transformative educational tool.
          </p>
          <p className="pt-5 text-start">
            By starting with a pilot implementation at Late Dattoba Ramchandra
            Kale municipal school in Pune for Grades 6, 7 and 8, VIDYA.AI will
            gradually scale to reach more students and redefine the learning
            experience for municipal schools.
          </p>
        </div>
      </div>
      {/* Footer */}
      <div className="bg-[black]">
        <div className=" flex lg:flex-row flex-col xl:px-0 lg:px-20 md:px-10 px-5 py-12 max-w-[1440px] mx-auto">
          <div className="lg:w-1/3 w-[100%] flex flex-col gap-2">
            <div className="flex gap-5 items-center text-white">
              <img src="./images/lotus-logo-light.svg" alt="lotus-logo" />
              <h1 className="font-black">VIDYA.AI</h1>
            </div>
            <p className="text-white text-start pt-3">
              VIDYA.ai is committed to reshaping education for those who need it
              most. By combining AI innovation with the Bala Bharathi
              curriculum, we ensure that every student has access to the tools
              they need to learn, grow, and succeed.
            </p>
          </div>
          <div className="lg:w-1/3 w-[100%] xl:ml-40 lg:ml-20 lg:mt-0 mt-5 ml-0 text-white text-start">
            <p className="text-white">
              For partnerships, collaborations, or queries, contact us at:
            </p>
            <b className="text-white">Email:ishaanpalicha@hotmail.com</b>
            <p className="text-white mt-3">
              Akansha Foundation, information and donation support:
            </p>
            <p>
              <b className="text-white">Email: fundraise@akanksha.org </b> or
              Donate online: https://www.akanksha.org/donation-page/
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
