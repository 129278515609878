import { Dialog, DialogBackdrop, DialogPanel, DialogTitle, Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser } from '../../store/user/user.selector';
import { AppDispatch } from '../../store/store';
import { updateUser } from '../../store/user/user.reducer';

interface LoginModalProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoginModal: React.FC<LoginModalProps> = ({ open, setOpen }) => {
    const dispatch: AppDispatch = useDispatch()
    const user = useSelector(selectCurrentUser)
    const token = user.access_token
    const [standard, setStandard] = useState<string>('')
    const [letter, setLetter] = useState<string>('')
    const [std, setStd] = useState<boolean>(false)
    const [lett, setLett] = useState<boolean>(false)

    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        try {
            const resultAction = dispatch(updateUser({ standard, letter, token }))
            if (updateUser.fulfilled.match(resultAction)) {
                setOpen(false)
            }
            console.log("Updated user:", resultAction)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        console.log("Login Modal Rendered!")
    }, [])

    return (
            <Dialog open={open} onClose={() => setOpen(false)} className="fixed inset-0 z-50">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                />
    
                <div className="fixed inset-0 z-50 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className="relative transform overflow-visible rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
                        >
                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                        <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            Set Details
                                        </DialogTitle>
                                        <div className="mt-2">
                                            <Menu as="div" className="relative inline-block text-left">
                                                <div>
                                                    <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                                        {standard ? `${standard}th Standard` : 'Standard'}
                                                        <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400" />
                                                    </MenuButton>
                                                </div>
                                                <MenuItems className="absolute left-0 z-50 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    <div className="py-1">
                                                        <MenuItem>
                                                            {({ active }) => (
                                                                <a
                                                                    onClick={() => {setStandard('6'); setStd(true)}}
                                                                    className={`block px-4 py-2 text-sm ${active ? 'bg-gray-100' : ''}`}
                                                                >
                                                                    6th Standard
                                                                </a>
                                                            )}
                                                        </MenuItem>
                                                        <MenuItem>
                                                            {({ active }) => (
                                                                <a
                                                                    onClick={() => {setStandard('7'); setStd(true)}}
                                                                    className={`block px-4 py-2 text-sm ${active ? 'bg-gray-100' : ''}`}
                                                                >
                                                                    7th Standard
                                                                </a>
                                                            )}
                                                        </MenuItem>
                                                        <MenuItem>
                                                            {({ active }) => (
                                                                <a
                                                                    onClick={() => {setStandard('8'); setStd(true)}}
                                                                    className={`block px-4 py-2 text-sm ${active ? 'bg-gray-100' : ''}`}
                                                                >
                                                                    8th Standard
                                                                </a>
                                                            )}
                                                        </MenuItem>
                                                    </div>
                                                </MenuItems>
                                            </Menu>
                                            <Menu as="div" className="relative inline-block text-left">
                                                <div>
                                                    <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                                        {letter || 'Letter'}
                                                        <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400" />
                                                    </MenuButton>
                                                </div>
                                                <MenuItems className="absolute left-0 z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    <div className="py-1">
                                                        <MenuItem>
                                                            {({ active }) => (
                                                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                                <a
                                                                    onClick={() => {setLetter('A'); setLett(true)}}
                                                                    className={`block px-4 py-2 text-sm ${active ? 'bg-gray-100' : ''}`}
                                                                >
                                                                    A
                                                                </a>
                                                            )}
                                                        </MenuItem>
                                                        <MenuItem>
                                                            {({ active }) => (
                                                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                                <a
                                                                    onClick={() => {setLetter('B'); setLett(true)}}
                                                                    className={`block px-4 py-2 text-sm ${active ? 'bg-gray-100' : ''}`}
                                                                >
                                                                    B
                                                                </a>
                                                            )}
                                                        </MenuItem>
                                                    </div>
                                                </MenuItems>
                                            </Menu>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                <button
                                    type="button"
                                    disabled={!lett || !std}
                                    onClick={handleSubmit}
                                    className="inline-flex w-full justify-center rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-400 sm:ml-3 sm:w-auto"
                                >
                                    Create
                                </button>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        );
}

export default LoginModal