import React, { useState, useEffect, FormEvent } from "react";
import "./register.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../store/store";
import { signup } from "../../store/user/user.reducer";
import { selectCurrentUser } from "../../store/user/user.selector";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import BASE_URL from "../../utils/apiurl";
import { toast, ToastContainer } from "react-toastify"; // Import Toastify
import "react-toastify/dist/ReactToastify.css"; // Import CSS for Toastify

type MessageType = "success" | "error";

interface Message {
  type: MessageType;
  text: string;
}

interface Schools {
  _id: string;
  school_name: string;
}

const Register = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [standard, setStandard] = useState<string>("");
  const [letter, setLetter] = useState<string>("");
  const [message, setMessage] = useState<Message | null>(null);
  const [schools, setSchools] = useState<Schools[] | null>(null);
  const [selectedSchool, setSelectedSchool] = useState<Schools | null>(null);
  const school_id: string = selectedSchool?._id!;
  const [submitting, setSubmitting] = useState(false);

  const passwordNotMatched =
    password.length > 0 &&
    confirmPassword.length > 0 &&
    password !== confirmPassword;

  const sendRequest = async () => {
    if (name && email && password && selectedSchool) {
      try {
        setSubmitting(true);
        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("school_id", school_id);
        formData.append("type", type)
        formData.append("password", password);
        const res = await axios.post(
          BASE_URL+"send-requests/",
          formData,
          {
            headers: {
              "Content-type": "multipart/form-data",
            },
          }
        );

        console.log(res.data);
        setMessage({ type: "success", text: "Sent a request to your principal." });
        setSubmitting(false);
      } catch (error:any) {
        setSubmitting(false);
        if (error.response) {
          if (error.response.status === 404) {
            setMessage({
              type: "error",
              text: error.response.data.message || "Email is already in use.",
            });
          } else {
            setMessage({
              type: "error",
              text: error.response.data.error || "Something went wrong.",
            });
          }
        } else {
          setMessage({
            type: "error",
            text: "An unexpected error occurred. Please try again.",
          });
        }  
        console.log(error);
      }
    }
  };

  const onSignUp = async () => {
    if (name && email && password && selectedSchool) {
      try {
        setSubmitting(true);
        const resultAction = await dispatch(
          signup({ name, email, password, type, standard, letter, school_id })
        );
        if (signup.fulfilled.match(resultAction)) {
          setMessage({ type: "success", text: "Signed up successfully!" });
          navigate("/login");
        } else {
          setSubmitting(false);
          setMessage({ type: "error", text: resultAction.payload as string });
        }
      } catch (error) {
        setSubmitting(false);
        setMessage({ type: "error", text: "An unexpected error occurred" });
      }
    } else {
      alert("Fill out all fields.");
    }
  };

  const fetchSchools = async () => {
    try {
      const res = await axios.get(BASE_URL+"get-schools/");
      setSchools(res.data.schools);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendRequest();
  };

  useEffect(() => {
    fetchSchools();
    setType("student");
    setLetter("A");
    setStandard("6");
  }, []);

  return (
    <div className="grid grid-cols-2 h-screen w-screen">
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="text-left text-2xl font-bold leading-9 tracking-tight text-neutral-900">
            Create a new account
          </h2>
          <p className="mt-5 text-left text-sm text-gray-500">
            Already have an account?{" "}
            <Link
              to="/login"
              className="font-bold leading-6 text-neutral-900 hover:text-amber-500"
            >
              Log in.
            </Link>
          </p>
        </div>

          <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div className="flex items-center space-x-4 mt-4">
                <div className="block">
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-bold leading-6 text-neutral-900"
                  >
                    You are?
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    id="6thstandard"
                    name="options"
                    type="radio"
                    className="h-4 w-4 text-amber-500 focus:ring-amber-500 border-gray-300"
                    value="student"
                    checked={type === "student"}
                    onChange={(e) => setType(e.target.value)}
                  />
                  <label
                    htmlFor="option1"
                    className="ml-2 block text-sm font-medium text-gray-900"
                  >
                    Student
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    id="option2"
                    name="options"
                    type="radio"
                    className="h-4 w-4 text-amber-500 focus:ring-amber-500 border-gray-300"
                    value="teacher"
                    checked={type === "teacher"}
                    onChange={(e) => setType(e.target.value)}
                  />
                  <label
                    htmlFor="option2"
                    className="ml-2 block text-sm font-medium text-gray-900"
                  >
                    Teacher
                  </label>
                </div>
              </div>
              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-bold leading-6 text-neutral-900"
                  >
                    Name:
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    type="text"
                    value={name}
                    required
                    onChange={(e) => setName(e.target.value)}
                    className="mt-2 block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-bold leading-6 text-neutral-900"
                  >
                    Email:
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    type="email"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    className="mt-2 block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-bold leading-6 text-neutral-900"
                  >
                    Password:
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    type="password"
                    value={password}
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    className="mt-2 block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <div className="flex items-center justify-between mt-6">
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-bold leading-6 text-neutral-900"
                  >
                    Confirm Password:
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    type="password"
                    value={confirmPassword}
                    required
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="mt-2 block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                {passwordNotMatched && (
                  <p className="text-red-500 text-xs mt-2 text-left">
                    Passwords does not match
                  </p>
                )}
                <div className="flex items-center space-x-4 mt-8">
                  <div className="block">
                    <label
                      htmlFor="firstName"
                      className="block text-sm font-bold leading-6 text-neutral-900"
                    >
                      Your school
                    </label>
                  </div>
                  {schools && (
                    <Menu as="div" className="relative flex text-left w-[74%]">
                      <MenuButton className="h-full flex w-full justify-between gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        {selectedSchool?.school_name || "Select School"}
                        <ChevronDownIcon
                          aria-hidden="true"
                          className="-mr-1 h-5 w-5 text-gray-400"
                        />
                      </MenuButton>
                      <MenuItems className="absolute left-0 top-8 z-10 mt-2 w-72 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {schools.map((school) => (
                            <MenuItem>
                              {({ active }) => (
                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                <a
                                  onClick={() => 
                                  {
                                    setSelectedSchool(school)
                                  }}
                                  className={`block px-4 py-2 text-sm text-black ${
                                    active ? "bg-gray-100" : ""
                                  }`}
                                >
                                  {school.school_name}
                                </a>
                              )}
                            </MenuItem>
                          ))}
                        </div>
                      </MenuItems>
                    </Menu>
                  )}
                </div>
              </div>
              {message && message.type === "error" && (
                <p className="font-semibold text-sm leading-6 text-red-600 ">
                  {message.text}
                </p>
              )}
              {message && message.type === "success" && (
                <p className="font-semibold text-sm leading-6 text-green-500">
                  {message.text}
                </p>
              )}
              <button
                type="submit"
                disabled={passwordNotMatched || submitting }
                className="flex w-full justify-center rounded-md bg-amber-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-amber-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {submitting ? (
                  <span className="animate-pulse">Submitting...</span>
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          </div>
      </div>
      <div className="bg-gradient-to-br from-amber-500 to-orange-500"></div>
    </div>
  );
};

export default Register;
