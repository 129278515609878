import React, { FormEvent, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../../store/user/user.reducer";
import { AppDispatch } from "../../store/store";

type MessageType = "success" | "error";

interface Message {
  type: MessageType;
  text: string;
}

interface LoginErrorResponse {
  message: string;
}

const Login = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [message, setMessage] = useState<Message | null>(null);
  const [submitting, setSubmitting] = useState(false);

  const onLogIn = async () => {
    if (email && password) {
      try {
        setSubmitting(true);
        const resultAction = await dispatch(login({ email, password }));
        console.log(resultAction);
        if (login.fulfilled.match(resultAction)) {
          setMessage({ type: "success", text: `Logged in successfully!` });
          navigate("/");
        } else {
          setSubmitting(false);
          const errorMessage = (resultAction.payload as { message?: string })?.message  || "Invalid email or password/Email is not verified.";
          setMessage({ type: "error", text: errorMessage });
        }
      } catch (error: any) {
        setSubmitting(false);
        if (error.response?.status === 404) {
          setMessage({ type: "error", text: "Fein"});
        }
        setMessage({ type: "error", text: "An unknown error occurred" });
      }
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onLogIn();
  };

  useEffect(() => {
    console.log(message)
  }, [message])

  return (
    <div className="grid grid-cols-2 h-screen w-screen">
      <div>
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="mt-10 text-left text-2xl font-bold leading-9 tracking-tight text-neutral-900">
              Log in to your account
            </h2>
            <p className="mt-5 text-left text-sm text-gray-500">
              Don't have an account?{" "}
              <Link
                to="/register"
                className="font-bold leading-6 text-neutral-900 hover:text-amber-500"
              >
                Sign up.
              </Link>
            </p>
          </div>
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="email"
                    className="block text-sm font-bold leading-6 text-neutral-900"
                  >
                    Email:
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    type="email"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-sm font-bold leading-6 text-neutral-900"
                  >
                    Password:
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    type="password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <div className="text-sm text-right mt-5">
                    <Link
                      to="/forget-password"
                      className="font-semibold text-amber-500 hover:text-amber-400"
                    >
                      Forgot password?
                    </Link>
                  </div>
                </div>
              </div>
              {message && message.type === "error" && (
                <p className="font-semibold text-sm leading-6 text-red-600">
                  {message.text}
                </p>
              )}
              {message && message.type === "success" && (
                <p className="font-semibold text-sm leading-6 text-green-500">
                  {message.text}
                </p>
              )}
              <button
                type="submit"
                disabled={submitting}
                className="flex w-full justify-center rounded-md bg-amber-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-amber-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {submitting ? (
                  <span className="animate-pulse">Submitting...</span>
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="bg-gradient-to-br from-amber-500 to-orange-500 flex items-center justify-center"></div>
    </div>
  );
};

export default Login;
