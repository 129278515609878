import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import BASE_URL from '../../utils/apiurl';

interface UserState {
  currentUser: any;
  isAuthenticated: boolean;
  error: string | null;
}

const initialState: UserState = {
  currentUser: null,
  isAuthenticated: false,
  error: null,
}

export const signup = createAsyncThunk(
    'user/signup',
    async (userData: { name: string; email: string; password: string; type: string; standard: string; letter: string; school_id: string}, { rejectWithValue }) => {
      try {
        const formData = new FormData();
        formData.append('name', userData.name);
        formData.append('email', userData.email);
        formData.append('password', userData.password);
        formData.append('type', userData.type)
        formData.append('standard', userData.standard)
        formData.append('letter', userData.letter)
        formData.append('school_id', userData.school_id)
        const response = await axios.post(BASE_URL+'signup/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        return response.data
      } catch (error: any) {
        const message = error.response?.data?.message || "An unknown error occured";
        return rejectWithValue({message});
      }
    }
  )
  
  // Async thunk for logging in
  export const login = createAsyncThunk(
    'user/login',
    async (credentials: { email: string; password: string }, { rejectWithValue }) => {
      try {
        const formData = new FormData();
        formData.append('email', credentials.email);
        formData.append('password', credentials.password);
  
        const response = await axios.post(BASE_URL+'login/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        return response.data
      } catch (error: any) {
        const message = error?.response?.data?.message || "An unknown error occurred ";
        return rejectWithValue({ message });
      }
    }
  )

  export const logout = createAsyncThunk(
    'user/logout',
    async (credentials: { email: string }, { rejectWithValue }) => {
        try {
            const formData = new FormData();
            formData.append('email', credentials.email)

            const response = await axios.patch(BASE_URL+'logout/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            return response.data
        } catch (error: any) {
            return rejectWithValue(error.response.data.message)
        }
    }
  )

  export const updateUser = createAsyncThunk(
    'user/update',
    async (credentials: { standard: string, letter: string, token: string }, { rejectWithValue }) => {
      try {
        const formData = new FormData();
        formData.append('standard', credentials.standard)
        formData.append('letter', credentials.letter)
        formData.append('token', credentials.token)

        const res = await axios.patch(BASE_URL+'update-user', formData, {
          headers: {
            'Content-type': 'multipart/form-data',
            "Authorization": `Bearer ${credentials.token}`
          }
        })
        return res.data
      } catch (error: any) {
          return rejectWithValue(error.res.data.message)
      }
    }
  )


const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setCurrentUser(state, action: PayloadAction<any>) {
            state.currentUser = action.payload;
            state.isAuthenticated = true;
            state.error = null;
          },
          checkUserSession(state) {},
          signInSuccess(state, action: PayloadAction<any>) {
            state.currentUser = action.payload;
            state.isAuthenticated = true;
            state.error = null;
          },
          signInFailure(state, action: PayloadAction<string>) {
            state.error = action.payload;
          },
          signUpStart(state) {
            state.error = null;
          },
          signUpSuccess(state, action: PayloadAction<any>) {
            state.currentUser = action.payload;
            state.isAuthenticated = true;
            state.error = null;
          },
          signUpFailure(state, action: PayloadAction<string>) {
            state.error = action.payload;
          },
          signOutStart(state) {},
          signOutSuccess(state) {
            state.currentUser = null;
            state.isAuthenticated = false;
            state.error = null;
          },
          signOutFailure(state, action: PayloadAction<string>) {
            state.error = action.payload;
          },
    },
    extraReducers: (builder) => {
        builder
          .addCase(signup.fulfilled, (state, action: PayloadAction<any>) => {
            const { user } = action.payload;
            state.currentUser = user;
            state.isAuthenticated = false;
            state.error = null;
          })
          .addCase(signup.rejected, (state, action) => {
            state.error = action.payload as string;
          })
          .addCase(login.fulfilled, (state, action: PayloadAction<any>) => {
            const { user } = action.payload;
            state.currentUser = user;
            state.isAuthenticated = true;
            state.error = null;
          })
          .addCase(login.rejected, (state, action) => {
            state.error = action.payload as string;
          })
          .addCase(logout.fulfilled, (state) => {
            state.currentUser = null;
            state.isAuthenticated = false;
            state.error = null;
          })
          .addCase(logout.rejected, (state, action) => {
            state.error = action.payload as string;
          })
          .addCase(updateUser.fulfilled, (state, action) => {
            const { user } = action.payload;
            state.currentUser = user;
            state.isAuthenticated = true;
            state.error = null;
          })
          .addCase(updateUser.rejected, (state, action) => {
            state.error = action.payload as string;
          })
      },
  })
  
  // Export actions for use in components
  export const { 
    setCurrentUser,
    checkUserSession,
    signInSuccess,
    signInFailure,
    signUpStart,
    signUpSuccess,
    signUpFailure,
    signOutStart,
    signOutSuccess,
    signOutFailure 
  } = userSlice.actions
  
  // Export reducer to configure the store
  export default userSlice.reducer