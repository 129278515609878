import React from 'react';
import './App.css';
import FileUpload from './routes/fileupload/fileupload.tsx';
import LessonList from './routes/lesson-list/lesson-list.tsx'
import { Route, Routes } from 'react-router-dom'
import NavBar from './routes/navbar/navbar.tsx';
import Register from './routes/register/register.tsx';
import Login from './routes/login/login.tsx';
import ClassList from './routes/class-list/class-list.tsx'
import RequestsPage from './routes/requests-page/requests-page.tsx'
import LandingPage from './routes/landing-page/landing-page.tsx'
import VerifyEmail from "./routes/verify-email/verify-email.tsx"
import ForgetPassword from './routes/forget-password/forget-password.tsx'
import ChangePassword from './routes/change-password/change-password.tsx'
import UsersList from './routes/users-list/users-list.tsx'
import { useSelector } from 'react-redux';
import { selectCurrentUser } from './store/user/user.selector.ts';

const App = () => {
  const user = useSelector(selectCurrentUser)
  
  return (
    <div className="App">
      <header >
        <Routes>
          <Route path='/register' element={<Register />}/>
          <Route path='/login' element={<Login />}/>
          <Route path="/verify" element={<VerifyEmail />}/>
          <Route path="/forget-password" element={<ForgetPassword />}/>
          <Route path="/pass" element={<ChangePassword />}/>
          {user.isAuthenticated ? (
            <Route path='/' element={<NavBar />}>
              <Route index={true} element={<ClassList />} />
              <Route path='/requests' element={<RequestsPage />} />
              <Route path='/users' element={<UsersList />} />
              <Route path='/transcribe/:cid' element={<FileUpload />} />
              <Route path='/:id' element={<LessonList />}/>         
            </Route>
          ) : (
            <Route path='/' element={<LandingPage />} />
          )}
        </Routes>
      </header>
    </div>
  );
}

export default App;