/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, ChangeEvent } from "react";
import {
  Link,
  useParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";
import axios from "axios";
import "./lesson-list.css";
import { AppDispatch } from "../../store/store";
import { logout } from "../../store/user/user.reducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/react";
import FileUpload from "../fileupload/fileupload";
import Edit from "../edit/edit";
import Delete from "../delete/delete";
import ChangeChapter from "../change-chapter/change-chapter";
import BASE_URL from "../../utils/apiurl";
import { toast, ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface Lesson {
  _id: string;
  standard: string;
  subject: string;
  chapter: string;
  teacher: string;
  teacher_id: string;
  name: string;
  created_at: string;
}

interface Classroom {
  standard: string;
  subject: string;
  letter: string;
  teacher: string;
}

const LessonList: React.FC = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const type = user.type;
  const email = user.email;
  const { id } = useParams<{ id: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [list, setList] = useState<Lesson[] | null>(null);
  const [classroom, setClassroom] = useState<Classroom | null>(null);
  const [activeTab, setActiveTab] = useState<"transcript" | "summary">(
    "summary"
  );
  const [lessonItem, setLesson] = useState<any>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false)

  const [lessonId, setLessonId] = useState<string>(
    searchParams.get("lessonId") ?? ""
  );
  const [listLoading, setListLoading] = useState(true);
  const [chapterOpen, setChapterOpen] = useState<boolean>(false)
  const [notification, setNotification] = useState<boolean>(false)
  const [notifDelete, setNotifDelete] = useState<boolean>(false)
  const [notifSuccess, setNotifSuccess] = useState<boolean>(false)

  const onLogOut = async () => {
    try {
      const resultAction = await dispatch(logout({ email }));
      console.log(resultAction.payload);
      if (logout.fulfilled.match(resultAction)) {
        console.log("Success:", resultAction.payload);
      } else {
        console.log("Failed to logout");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchLessonList = async () => {
    try {
      setListLoading(true);
      const res = await axios.get(`${BASE_URL}get-lessons/${id}`, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });
      console.log({res: res.data})
      setList(res.data.lessons);
      setClassroom(res.data.classroom);
      setLessonId(res?.data?.lessons?.[0]?._id);
      setSearchParams({ lessonId: res?.data?.lessons?.[0]?._id });
    } catch (error) {
      setListLoading(false);
      setList(null)
      setClassroom(null);
      setLessonId("");
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        navigate("");
        onLogOut();
      } else {
        console.log(error);
      }
    }
  };

  const fetchLessonById = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}get-lesson/${lessonId}`,
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        }
      );
      setLesson(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const downloadFile = async () => {
    const formData = new FormData();
    formData.append("transcript", lessonItem.transcript);
    formData.append("summary", lessonItem.summary);
    formData.append("standard", lessonItem.standard);
    formData.append("subject", lessonItem.subject);
    formData.append("chapter", lessonItem.chapter);
    formData.append("name", lessonItem.name);
    try {
      const res = await axios.post(BASE_URL+"download/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.access_token}`,
        },
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: "application/pdf" })
      );
      const link = document.createElement("a");
      let path = lessonItem.name + ".pdf";
      link.href = url;
      link.setAttribute("download", path);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        navigate("");
        onLogOut();
      } else {
        console.log(error);
      }
    }
  };

  const deleteLesson = async () => {
    try {
      const res = await axios.delete(
        `${BASE_URL}delete/${lessonId}`,
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        }
      );
      console.log(res.data);
      setDeleteOpen(false)
        fetchLessonList();
        setNotifDelete(true);
    //   await fetchLessonById();
    //   navigate(-1);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        navigate("");
        onLogOut();
      } else {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchLessonList();
  }, []);

  useEffect(() => {
    if (lessonId) fetchLessonById();
  }, [lessonId]);

  useEffect(() => {
    if (notifSuccess) 
    {
      toast.success(`Successfully created!`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setNotifSuccess(false)
    }
  }, [notifSuccess]);

  useEffect(() => {
    if (notification) 
    {
      fetchLessonById();
      toast.success(`Successfully updated!`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setNotification(false);
    }
  }, [notification]);

  useEffect(() => {
    if (notifDelete) 
    {
      fetchLessonById();
      toast.error(`Successfully deleted!`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "black",
        transition: Bounce,
      });
      setNotifDelete(false);
    }
  }, [notifDelete]);


  return (
    <>
    <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
              transition={Bounce}
              toastClassName="bg-white text-sm text-gray-900 border border-gray-200 rounded-md shadow-md p-4 max-w-xs flex items-center" // Tailwind classes for the toast
              bodyClassName="whitespace-nowrap overflow-hidden text-ellipsis" 
    />
      {!list && !listLoading ? (
        (user.type === "teacher" || user.type === "admin" || user.type === "principal") ? (
          <div className="flex flex-col items-center justify-center h-screen w-full">
            <p className="font-bold text-gray-700 -mt-48 truncate text-lg leading-5 mb-6">
              We could not found any lessons for your class, you can create a
              lesson here to get started
            </p>
            <button
              onClick={() => setOpen(!open)}
              className="text-lg px-4 py-2 rounded-md bg-gradient-to-br from-amber-500 to-orange-500 text-white flex items-center justify-center shadow-lg hover:bg-blue-600 transition-colors"
            >
              Create lesson
            </button>
          </div>
        ) : (
          <>
            <div className="flex flex-col items-center justify-center h-screen w-full">
              <p className="font-bold text-gray-700 -mt-48 truncate text-lg leading-5 mb-6">
                We could not found any lessons for your class!!
              </p>
            </div>
          </>
        )
      ) : null}
      <div className="max-w-7xl mx-auto p-4 grid grid-cols-3 grid-rows-[auto_auto] gap-4 items-stretch">
        {classroom && lessonItem && (
          <>
            {/* First Box (Main Content) */}

            <div className="relative col-span-2 row-span-2 bg-white rounded-lg ring-1 ring-inset ring-gray-200 p-6 text-black text-left flex flex-col h-[82vh]">
              <div className="flex flex-row justify-between">
                <div>
                  <h1 className="flex flex-2 flex-col text-xl font-bold mb-4">
                    {classroom.standard}
                    {classroom.letter} - {classroom.subject} -{" "}
                    {lessonItem.chapter} - {lessonItem?.name}
                  </h1>
                  <h1 className="flex flex-2 flex-col text-l mb-4">
                    {lessonItem?.created_at}
                  </h1>
                  <div className="flex min-w-0 gap-x-4 pb-6">
                    { !lessonItem.imageUrl &&  
                                <div className="relative inline-flex items-center justify-center w-12 h-12 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                                <span className="font-medium text-gray-600 dark:text-gray-300">{lessonItem.teacher[0].toUpperCase()}</span>
                                </div> }
                    {lessonItem.imageUrl && <img alt="" src={lessonItem.imageUrl} className="h-12 w-12 flex-none rounded-full bg-gray-50" /> }
                    <div className="min-w-0 flex-auto">
                      <p className="text-sm font-semibold leading-6 text-gray-900">
                        {lessonItem.teacher}
                      </p>
                      <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                        Teacher
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-3 flex-row gap-4">
                  {type === "teacher" || type === "admin" || type === "principal" ? (
                    <>
                      <button
                        onClick={() => {setEditOpen(!editOpen)
                        }}
                        className="text-base px-4 py-2 h-10 rounded-md border-2 border-cyan-500 text-cyan-500 flex items-center justify-center shadow-sm hover:bg-gradient-to-br from-cyan-400 to-blue-500 hover:text-white hover:border-transparent"
                      >
                        Edit
                      </button>
                      
                      <button
                        onClick={() => setDeleteOpen(!deleteOpen)}
                        className="text-base px-4 py-2 h-10 rounded-md border-2 border-red-400 text-red-400 flex items-center justify-center shadow-sm hover:bg-gradient-to-br from-red-500 to-red-400 hover:text-white hover:border-transparent"
                      >
                        Delete
                      </button>
                      <button 
                        onClick={() => setOpen(!open)} 
                        className="text-base px-4 py-2 h-10 rounded-md bg-gradient-to-br from-amber-500 to-orange-500 text-white flex items-center justify-center shadow-lg hover:bg-blue-600 transition-colors"
                        >
                        Upload Lesson
                      </button>
                      
                    </>
                  ) : null}
                </div>
              </div>

              {/* Tabs */}
              <div className="flex justify-start mb-5">
                <div
                  className={`text-base py-2 px-5 mx-1 cursor-pointer border-b-2 ${
                    activeTab === "summary"
                      ? "border-black"
                      : "border-transparent"
                  } hover:border-gray-300`}
                  onClick={() => setActiveTab("summary")}
                >
                  Summary
                </div>
                {(type === "teacher" || type === "admin" || type === "principal") && (
                    <div
                    className={`text-base py-2 px-5 mx-1 cursor-pointer border-b-2 ${
                    activeTab === "transcript"
                        ? "border-black"
                        : "border-transparent"
                    } hover:border-gray-300`}
                    onClick={() => setActiveTab("transcript")}
                >
                    Transcript
                </div>
                )}
              </div>

              {/* Content */}
              <div className="w-full max-w-2xl flex-grow overflow-y-auto">
                {activeTab === "transcript" && (type === "teacher" || type === "admin") && (
                  <div className="max-h-[50vh] p-2 rounded bg-white text-sm whitespace-pre-wrap text-black">
                    <p>{lessonItem.transcript}</p>
                  </div>
                )}
                {activeTab === "summary" && (
                  <div className="max-h-[50vh] p-2 rounded bg-white text-sm whitespace-pre-wrap text-black">
                    <p>{lessonItem.summary}</p>
                  </div>
                )}
              </div>
            </div>

            {/* Second Smaller Box */}
            <div className="col-span-1 bg-white rounded-lg ring-1 ring-inset ring-gray-200 p-6 text-black text-left h-[40vh] flex flex-col">
              <div className="flex  gap-x-4 h-15 items-center">
                <h1 className="text-l font-semibold mb-4">Lessons</h1>
                {type === "admin" && (
                  <button
                    onClick={() => setChapterOpen(!chapterOpen)}
                    className="text-base px-4 py-2 h-8 rounded-md border-2 border-green-400 text-green-400 flex items-center justify-center shadow-sm hover:bg-gradient-to-br from-green-500 to-green-400 hover:text-white hover:border-transparent"
                  >
                    Change Chapter Name
                  </button>
                )}
              </div>

              {list && (
                <div className="overflow-y-auto flex-grow">
                  <ul className="divide-y divide-gray-100 max-h-full">
                    {list.map((lesson) => (
                      <li
                        key={lesson._id}
                        className={`flex justify-between gap-x-6 py-3 ${
                          lesson._id.toString() === lessonItem._id && "bg-amber-500 rounded-lg px-2"
                        }`}
                      >
                        <a
                          href="#"
                          onClick={async (e) => {
                            e.preventDefault();
                            await fetchLessonById();
                            setLessonId(lesson._id);
                            setSearchParams(lesson._id);
                          }}
                          className="flex min-w-0 gap-x-4"
                        >
                          <div className="min-w-0 flex-auto">
                            <p
                              className={`text-sm font-semibold text-left ${
                                lesson._id.toString() === lessonItem._id ? "text-white" : "text-gray-900"
                              }`}
                            >
                              Teacher: {lesson?.teacher}
                            </p>
                            <p
                              className={`truncate text-xs text-left ${
                                lesson._id.toString() === lessonItem._id ? "text-white" : "text-gray-900"
                              }`}
                            >
                              Chapter: {lesson.chapter} <br />
                              Lesson: {lesson?.name}<br/>
                              Date: {lesson?.created_at}
                            </p>
                          </div>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            {/* Third Smaller Box (Beneath the Second Box) */}
            <div className="col-span-1 bg-white rounded-lg ring-1 ring-inset ring-gray-200 p-6 text-black text-left h-[40vh]">
              <div className="flex min-w-0 gap-x-4 h-12">
                <h1 className="text-xl font-semi mb-4">Links</h1>
              </div>
              <ul className="divide-y divide-gray-100 max-h-[45vh]">
                <li
                  className="flex justify-between gap-x-6 py-3 text-sm font-bold hover:text-amber-500 cursor-pointer"
                  onClick={downloadFile}
                >
                  Download PDF
                </li>
                <li className="flex justify-between gap-x-6 py-3 text-sm font-bold hover:text-amber-500 cursor-pointer">
                  <a
                    href={`${lessonItem.s3_audio}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Get Audio File
                  </a>
                </li>
              </ul>
            </div>
          </>
        )}
        {open === true && (
          <FileUpload
            open={open}
            setOpen={setOpen}
            cid={id}
            fetchLessonById={fetchLessonById}
            fetchLessonList={fetchLessonList}
            setNotif={setNotifSuccess}
          />
        )}
        {editOpen === true && (
          <Edit open={editOpen} setOpen={setEditOpen} setNotif={setNotification} lesson={lessonItem} />
        )}
        {deleteOpen === true && (
            <Delete open={deleteOpen} setOpen={setDeleteOpen} deleteLesson={deleteLesson} message="Are you sure you want to delete the lesson? This change will be permanent." name="Lesson" />
        )}
        {chapterOpen === true && (
          <ChangeChapter
            open={chapterOpen}
            setOpen={setChapterOpen}
            cid={id}
            fetchLessonById={fetchLessonById}
            fetchLessonList={fetchLessonList}
          />
        )}
      </div>
    </>
  );
};

export default LessonList;
